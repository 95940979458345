<template>
  <div class="add-funds-container">
    <div class="balance-section">
      <div class="text">
        <span class="balance-text"> Balance</span>
        <h2 class="user-balance">
          <sup>₦</sup>
          {{ user && user.balance ? user.balance : 0.0 }}
        </h2>
      </div>
      <!-- 
      <nav class="add-cta">
        <ul class="links">
          <button @click="$refs.modal.open()" class="add-btn btn">
            <i class="fa-solid fa-plus"></i>
            <span>Add Funds</span>
          </button>
          <button @click="$refs.modal.open()" class="add-btn btn">
            <i class="fa-solid fa-share"></i>
            <span>Transfer</span>
          </button>
        </ul>
      </nav> -->
      <!-- <div class="actions">
        <sweet-modal ref="modal">
          <sweet-modal-tab title="Flutterwave" id="flv">
            <div class="add-container">
              <div class="method-image">
                <img
                  src="../../assets/images/flutterwave.svg"
                  alt="Flutterwave"
                />
              </div>
              <form v-on:submit.prevent>
                <input
                  placeholder="Amount : Min (₦ : 100)"
                  type="number"
                  class="price-input input"
                />
                <button class="btn">Pay</button>
              </form>
            </div>
          </sweet-modal-tab>
          <sweet-modal-tab title="Coinbase" id="cb">
            <div class="add-container">
              <div class="method-image">
                <img src="../../assets/images/coinbase.svg" alt="Flutterwave" />
              </div>
              <form v-on:submit.prevent>
                <input
                  placeholder="Amount : Min (₦ : 100)"
                  type="number"
                  class="price-input input"
                />
                <button class="btn">Pay</button>
              </form>
            </div></sweet-modal-tab
          >
          <sweet-modal-tab title="Manual" id="manual">
            After a successful manual payment please contact us (<a
              href="http://wa.me/2349129498210"
              target="_blank"
              rel="noopener noreferrer"
              >+2349129498210</a
            >) in order to fund your account<br />

            <div class="bank-transfers">
              <div class="bank-transfer">
                <div class="bank-image">
                  <img src="../../assets/images/bank.png" alt="Bank" />
                </div>
                <div class="bank-details">
                  <h3 class="bank-name">Bank</h3>
                  <p class="bank-account">
                    Account Name: Gainer<br />
                    Account Number: 7822889175<br />
                    Bank Name: Wema Bank PLC<br />
                  </p>
                </div>
              </div>
              <div class="bank-transfer">
                <div class="bank-image">
                  <img src="../../assets/images/bank.png" alt="Bank" />
                </div>
                <div class="bank-details">
                  <h3 class="bank-name">Bank</h3>
                  <p class="bank-account">
                    Account Name: Gainer FLW<br />
                    Account Number: 8222274890<br />
                    Bank Name: Sterling Bank PLC<br />
                  </p>
                </div>
              </div>
            </div>
          </sweet-modal-tab>
        </sweet-modal>
      </div> -->
    </div>
    <div class="tabbed-session">
      <!-- <nav class="tabbed-nav">
        <ul class="nav-links">
          <li>
            <router-link
              :to="{ name: 'AddFunds' }"
              :class="{
                active: !$route.query.tab,
              }"
            >
              Add Fund
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                query: {
                  tab: 'transfer',
                },
              }"
              :class="{
                active: getActiveRoute('transfer'),
              }"
            >
              Transfer
            </router-link>
          </li>
        </ul>
      </nav> -->
      <div class="inner-route-area">
        <div class="add">
          <form :class="{ loading: isLoading }" @submit="handleSubmit">
            <div v-show="alert.show" class="notifications-section">
              <div class="alert" :class="alert && alert.type ? alert.type : ''">
                <span class="alert-text">{{ alert.message }}</span
                ><i @click="closeAlert" class="fa-solid fa-x pointer"></i>
              </div>
            </div>
            <div class="main-input">
              <select v-model="payInput.type" class="input" name="type">
                <option value="">Select Payment Type</option>
                <option value="flw">Flutterwave</option>
                <option value="coinbase">Coinbase</option>
                <option value="manual">Manual (Bank Transfer)</option>
                <!-- <option value="manualC">Manual (Crypto)</option> -->
              </select>
            </div>
            <div v-if="payInput.type !== 'manual'" class="main-input top">
              <input
                placeholder="Amount: Min (₦ : 100)"
                type="number"
                min="100"
                class="input"
                name="amount"
                v-model="payInput.amount"
              />
            </div>
            <div v-if="payInput.type !== 'manual'" class="pay-btn-wrap">
              <button
                type="submit"
                :disabled="isLoading"
                class="btn btn-primary"
              >
                <i class="fa-solid fa-spinner spin" v-if="isLoading"></i>

                <span v-else class="pay-text"> Pay </span>
              </button>
            </div>
          </form>
        </div>
        <div v-if="payInput.type === 'manual'" class="description-pay-section">
          <p class="instructions-centre">
            After a successful payment please contact us (<a
              href="http://wa.me/2349129498210"
              target="_blank"
              rel="noopener noreferrer"
              >+2349129498210</a
            >) or create a new ticket in order to fund your account
          </p>
          <div class="bank-sections" v-if="payInput.type === 'manual'">
            <div class="bank-transfer">
              <div class="bank-image">
                <img src="../../assets/images/bank.png" alt="Bank" />
              </div>
              <div class="bank-details">
                <h3 class="bank-name">Bank</h3>
                <p class="bank-account">
                  Account Name: Gainer<br />
                  Account Number: 7822889175<br />
                  Bank Name: Wema Bank PLC<br />
                </p>
              </div>
            </div>
            <div class="bank-transfer">
              <div class="bank-image">
                <img src="../../assets/images/bank.png" alt="Bank" />
              </div>
              <div class="bank-details">
                <h3 class="bank-name">Bank</h3>
                <p class="bank-account">
                  Account Name: Gainer FLW<br />
                  Account Number: 8222274890<br />
                  Bank Name: Sterling Bank PLC<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="transactions">
      <span class="transactions-head">Transactions</span>
      <div class="scroll-section">
        <div
          class="empty"
          v-if="!transactions || !transactions.length || fetchingTransactions"
        >
          <span v-if="fetchingTransactions">Loading transactions...</span>
          <span v-else> You have not made any transaction yet </span>
        </div>
        <div v-for="item in transactions" :key="item._id" class="list-item">
          <div class="left">
            <i class="fa-solid fa-plus"></i>
            <div class="main-text">
              <span class="top-up-text">Top Up</span>
              <span class="method">{{ item.type }}</span>
            </div>
          </div>
          <div class="right">
            <span class="amount">₦{{ item.amount }}</span>
            <!-- <span class="date">{{ item.date }}</span> -->
            <span class="date">
              {{ item.date | moment("MMMM Do YYYY") }}
            </span>
          </div>
        </div>
        <!-- <div v-for="item in [1, 2, 3, 4, 5, 6]" :key="item" class="list-item">
          <div class="left">
            <i class="fa-solid fa-minus"></i>
            <div class="main-text">
              <span class="top-up-text">Top Up</span>
              <span class="method">Bank Transfer</span>
            </div>
          </div>
          <div class="right">
            <span class="amount">₦ 4,500</span>
            <span class="date">Mar 07, 2022</span>
          </div>
        </div> -->
        <infinite-loading
          v-if="transactions.length >= 20"
          @infinite="fetchFundsHistory"
        ></infinite-loading>
      </div>
    </div>
  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapState } from "vuex";
export default {
  name: "AddFunds",
  metaInfo: {
    title: "The Gainer",
    titleTemplate: "%s | Add Funds",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      isLoading: false,
      pagPage: 1,
      payInput: {
        type: "",
        amount: "",
      },
      alert: {
        type: "danger",
        message: "",
        show: false,
      },
    };
  },
  components: {
    InfiniteLoading,
  },
  methods: {
    ...mapActions(["getTransactions"]),

    fetchFundsHistory(state) {
      this.getTransactions({
        page: this.pagPage,
        limit: 20,
      })
        .then((res) => {
          if (res.length) {
            this.pagPage++;
            state.loaded();
          } else {
            state.complete();
          }
        })
        .catch(() => {
          state.complete();
        });
    },

    getActiveRoute(item) {
      return (
        this.$route &&
        this.$route.query &&
        this.$route.query.tab &&
        this.$route.query.tab === item
      );
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.payInput.type) {
        this.alert.message = "Please select a payment method";
        this.alert.show = true;
        this.alert.type = "danger";
        return;
      } else if (!this.payInput.amount) {
        this.alert.message = "Please enter an amount";
        this.alert.show = true;
        this.alert.type = "danger";
        return;
      } else if (Number(this.payInput.amount) < 100) {
        this.alert.message = "Minimum amount is ₦ 100";
        this.alert.show = true;
        this.alert.type = "danger";
        return;
      }
      this.closeAlert();
      this.isLoading = true;

      this.$http
        .post("payements/init", this.payInput)
        .then((res) => {
          window.location.href = res.data.data.link;
        })
        .catch((err) => {
          this.isLoading = false;
          if (!err || (!err.response && !window.navigator.onLine)) {
            this.alert = {
              type: "danger",
              message: "You are currently offline",
              show: true,
            };
            return;
          } else if (!err || !err.response) {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
            return;
          }
          if (err.response.data && err.response.data.message) {
            this.alert = {
              type: "danger",
              message: err.response.data.message,
              show: true,
            };
          } else {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
          }
        });
    },
    closeAlert() {
      this.alert = { ...this.alert, show: false };
    },
  },
  computed: {
    ...mapState(["user", "transactions", "fetchingTransactions"]),
  },
  created() {
    this.$watch(
      () => this.user,
      () => {
        if (this.user && this.user.userId && !this.transactions.length) {
          this.getTransactions();
          this.pagPage += 1;
        }
      },
      { immediate: true }
    );
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
